import {useContext} from 'react';
import Image from 'next/image';
import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import VobApp from 'aac-components/components/VobApp';
import Byline from '../Byline';
import MaxWidth from '../MaxWidth';
import MedicallyReviewed from '../MedicallyReviewed';
import AppContext from '../AppContext';
import PagePills from '../PagePills';
import WhatYouWillLearn from '../WhatYouWillLearn';
import {
    HeroContainerStyled,
    TextStyled,
    HeroBreadcrumbsContainerStyled,
    OverlayStyled,
    HeroContentContainerStyled,
    HeroContentMainStyled,
    H1Styled,
    HeroCopyStyled,
    HeroContentLeftStyled,
    HeroContentRightStyled,
    VobStyled,
} from './LocalTemplateHeroStyles';

const LocalTemplateHero = (props) => {
    const {
        data: {
            id = 0,
            title: {rendered: title = ''} = {},
            byline_info = {},
            modified = '',
            acf: {
                'rehab-guide-learn': highlights = [],
                'rehab-guide-summary': summary = '',
            } = {},
        },
    } = props;

    const {isMobile} = useContext(AppContext);

    const useFeaturedImage =
        props?.data?.featured_image && props.data.featured_image.length > 0;

    const imageSource = useFeaturedImage
        ? props?.data?.featured_image[props?.data?.featured_image.length - 1]
        : '/static/aac_hero_bg_desktop.jpg';

    return (
        <TextStyled useFeaturedImage={useFeaturedImage}>
            <HeroContainerStyled>
                {!isMobile && (
                    <>
                        <Image
                            src={imageSource}
                            priority={true}
                            fill
                            style={{objectFit: 'cover', background: 'var(--primary-400)'}}
                            alt="American Addiction Centers Photo"
                            sizes="100vw"
                        />
                        {useFeaturedImage && <OverlayStyled />}
                    </>
                )}
                <MaxWidth>
                    {!isMobile && (
                        <HeroBreadcrumbsContainerStyled>
                            <Breadcrumbs IconFill={useFeaturedImage ? 'white' : ''} />
                        </HeroBreadcrumbsContainerStyled>
                    )}
                    <HeroContentContainerStyled>
                        <HeroContentMainStyled useFeaturedImage={useFeaturedImage}>
                            {isMobile && (
                                <HiddenOnDesktop>
                                    <Image
                                        src={imageSource}
                                        priority={true}
                                        fill
                                        style={{objectFit: 'cover'}}
                                        alt="American Addiction Centers Photo"
                                    />
                                    {useFeaturedImage && <OverlayStyled />}
                                </HiddenOnDesktop>
                            )}
                            <HeroContentLeftStyled>
                                {isMobile && (
                                    <HeroBreadcrumbsContainerStyled>
                                        <Breadcrumbs />
                                    </HeroBreadcrumbsContainerStyled>
                                )}
                                <MedicallyReviewed bylineData={byline_info} />
                                <H1Styled dangerouslySetInnerHTML={{__html: title}} />
                                <Byline
                                    contributors={byline_info}
                                    lastModified={modified}
                                />

                                <PagePills />

                                {summary && (
                                    <HeroCopyStyled
                                        dangerouslySetInnerHTML={{__html: summary}}
                                    />
                                )}
                                {highlights && (
                                    <WhatYouWillLearn highlights={highlights} />
                                )}
                            </HeroContentLeftStyled>
                        </HeroContentMainStyled>
                        <HeroContentRightStyled>
                            <VobStyled useFeaturedImage={useFeaturedImage}>
                                <VobAbandonModal>
                                    <VobApp
                                        automationTestingSelector="vob-hero"
                                        type="multi-step"
                                    />
                                </VobAbandonModal>
                            </VobStyled>
                        </HeroContentRightStyled>
                    </HeroContentContainerStyled>
                </MaxWidth>
            </HeroContainerStyled>
        </TextStyled>
    );
};
export default LocalTemplateHero;
