import styled from 'styled-components';
import {BREAKPOINT} from 'aac-components/utils/styles';

export const VobStyled = styled.div`
    @media screen and (min-width: ${BREAKPOINT}) {
        width: 400px;
    }
    .vob-ms {
        border: ${(props) => (props.useFeaturedImage ? '' : '1px solid var(--gray-300)')};
        border-radius: 8px;
    }
`;

export const TextStyled = styled.div`
    .breadcrumbs__li a,
    .breadcrumbs__text,
    .breadcrumbs__slash,
    .c-byline__text,
    .c-byline__entry {
        color: ${(props) => (props.useFeaturedImage ? 'white !important' : '')};
    }
`;

export const HeroContainerStyled = styled.div`
    position: relative;
    min-width: 100%;
    padding: 0;
    margin-bottom: 25px;

    @media screen and (min-width: ${BREAKPOINT}) {
        padding: 24px 0 42px 0;
        margin-bottom: 40px;
    }
`;

export const HeroBreadcrumbsContainerStyled = styled.div`
    position: relative;
    margin-bottom: 8px;

    @media screen and (min-width: ${BREAKPOINT}) {
        margin-bottom: 50px;
    }
`;

export const OverlayStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const HeroContentContainerStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    font-size: 16px;
    position: relative;
    color: ${(props) => (props.useFeaturedImage ? 'white' : 'black')};

    @media screen and (min-width: ${BREAKPOINT}) {
        grid-template-columns: 2fr 1fr;
        grid-gap: 150px;
        width: 100%;
    }
`;

export const HeroContentMainStyled = styled.div`
    position: relative;
    padding: 8px 15px 24px 15px;
    color: ${(props) => (props.useFeaturedImage ? 'white' : 'black')};

    @media screen and (min-width: ${BREAKPOINT}) {
        padding: 0;
        margin-bottom: 0;
    }
`;

export const HeroContentLeftStyled = styled.div`
    position: relative;
`;

export const HeroContentRightStyled = styled.div`
    padding: 30px 15px 30px 15px;
    background: var(--primary-100);

    @media screen and (min-width: ${BREAKPOINT}) {
        padding: 0;
        background: none;
    }
`;

export const H1Styled = styled.h1`
    margin-bottom: 8px;
`;

export const HeroCopyStyled = styled.div`
    margin-bottom: 16px;
    line-height: 28px;
`;
